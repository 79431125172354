import { render, staticRenderFns } from "./img-block.vue?vue&type=template&id=4f9dc851&scoped=true&"
import script from "./img-block.vue?vue&type=script&lang=js&"
export * from "./img-block.vue?vue&type=script&lang=js&"
import style0 from "./img-block.vue?vue&type=style&index=0&id=4f9dc851&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f9dc851",
  null
  
)

export default component.exports